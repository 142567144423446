<template>
  <div
    v-if="isPassenger"
    class="forgot-password-wrapper">
    <v-container grid-list-xl>
      <div class="custom-container text-center">
        <div>
          <div class="mb-5">
            <img
              src="../../assets/images/logo.svg"
              alt="Logo">
          </div>
          <div
            v-if="linkSent"
            class="custom-paragraph black--text mb-3">
            Indien uw account bij ons bekend is ontvangt u een email met daarin de instructies voor het wachtwoordherstel. Als het niet lukt kunt u contact opnemen met <a
              class="custom-link lightBlue--text"
              href="mailto:support@driveme.nl">support@driveme.nl</a>
          </div>
          <div v-else>
            <div class="custom-title black--text mb-1">Wachtwoord vergeten</div>
            <div class="custom-paragraph black--text mb-2">Vul uw e-maildres in en wij sturen u een wachtwoord reset link</div>
            <v-form
              ref="resetForm"
              v-model="valid"
              @submit.prevent>
              <v-text-field
                :max-length="TEXT_FIELD_MAX_LENGTH"
                v-model="email"
                :rules="emailRules"
                color="black"
                background-color="ashyGray"
                class="custom-input py-0"
                placeholder="E-mail"
                required
                @keyup.enter="resetpassword"
              />
              <v-btn
                id="forgot-password-btn"
                :disabled="!valid"
                class="btn-submit white--text"
                color="black"
                block
                @click="resetpassword"><span :class="`${valid ? 'white--text' : 'black--text'}`">stuur reset link</span></v-btn>
            </v-form>
          </div>
          <div>
            <v-btn
              v-if="linkSent"
              class="btn-submit white--text"
              color="black"
              block
              to="/session/login">Terug naar login</v-btn>
            <router-link
              v-else
              class="custom-link lightBlue--text mt-2"
              to="/session/login">Terug naar login</router-link>
          </div>
        </div>
      </div>
    </v-container>
  </div>
  <div
    v-else
    :style="{ background: loginScreenStyle.background }"
    class="session-wrapper login-screen">
    <v-container
      grid-list-xl
      class="column-layout justify-center full-height">
      <div class="text-xs-center">
        <div class="session-table-cell row-layout-center">
          <div class="session-content login-content">
            <div class="login-logo">
              <img
                :src="require('../../assets/images/logo.svg')"
                alt="Drive Me">
            </div>
            <div
              v-if="linkSent"
              class="white-14 mb-2 pb-2">
              Indien uw account bij ons bekend is ontvangt u een email met daarin de instructies voor het wachtwoordherstel. Als het niet lukt kunt u contact opnemen met <a href="mailto:support@driveme.nl">support@driveme.nl</a>
            </div>
            <div v-else>
              <h2 class="mb-3 white-18">Wachtwoord vergeten</h2>
              <p class="fs-14 white-12">Vul uw e-maildres in en wij sturen u een wachtwoord reset link</p>
              <v-form
                ref="resetForm"
                v-model="valid"
                class="mb-4"
                @submit.prevent>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="email"
                  :rules="emailRules"
                  dark
                  label="E-mail"
                  required
                  @keyup.enter="resetpassword"
                />
                <v-btn
                  id="forgot-password-btn"
                  :disabled="!valid"
                  large
                  dark
                  color="pink"
                  block
                  @click="resetpassword"><span class="white-14">stuur reset link</span></v-btn>
              </v-form>
            </div>

            <div>
              <v-btn
                v-if="linkSent"
                color="pink"
                large
                dark
                block
                to="/session/login">Terug naar login</v-btn>
              <router-link
                v-else
                class="white-14"
                to="/session/login">Terug naar login</router-link>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import SessionSliderWidget from 'Components/Widgets/SessionSlider'
import Request from '../../helpers/request'
import { TEXT_FIELD_MAX_LENGTH } from '../../constants/common'
import { getEnvVersion } from '../../helpers/helpers'

export default {
  components: {
    SessionSliderWidget
  },
  mixins: [Request],
  data () {
    return {
      TEXT_FIELD_MAX_LENGTH,
      email: '',
      valid: true,
      emailRules: [
        v => !!v || 'Is verplicht'
      ],
      linkSent: false,
      isPassenger: false
    }
  },
  computed: {
    loginScreenStyle () {
      return {
        background: `linear-gradient(to bottom, ${this.$store.getters.colorGuide.login.gradient.from} 40vh, ${this.$store.getters.colorGuide.login.gradient.to} 100%), url(${this.$store.getters.colorGuide.login.image}) ${this.$vuetify.breakpoint.xsOnly ? this.$store.getters.colorGuide.login.imageParams : this.$store.getters.colorGuide.login.imageParamsDesktop}`
      }
    }
  },
  beforeMount () {
    this.isPassenger = getEnvVersion().siteVersion === 'ROLE_PASSENGER'
  },
  methods: {
    resetpassword () {
      if (this.$refs.resetForm.validate()) {
        let body = {
          data: {
            email: this.email
          }
        }
        this.requestNoAuth('POST', '/emailing/user-reset-password', body, ({ data }) => {
          this.linkSent = true

          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Een link is toegestuurd om je wachtwoord te herstellen'
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-content {
      width: 90%;
      max-width: 350px;
}
.login-logo {
    margin-bottom: 20%;
    margin-top: 40px;
}
  .forgot-password-wrapper {
    height: 100%;
    background-color: #ffffff;

    .container {
      height: 100%;

      .custom-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
</style>
